<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="briefcase"></b-icon>
          <span>{{ $ml.get('titles_strategies') }}</span>
        </h4>
        <b-button tag="router-link" type="is-primary" outlined :to="{ path: `/strategies/create` }">
          <span>{{ $ml.get('common_create') }}</span>
        </b-button>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :loading="loading"
            :per-page="perPage">
          <b-table-column field="name" :label="$ml.get('strategies_strategy')" sortable searchable cell-class="leftal" v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="public" width="50" :label="$ml.get('strategies_mode')" sortable cell-class="leftal" v-slot="props">
            <span v-if="props.row.mode === 'PUBLIC'">{{ $ml.get('strategies_mode_public') }}</span>
            <span v-if="props.row.mode === 'PRIVATE'">{{ $ml.get('strategies_mode_private') }}</span>
            <span v-if="props.row.mode === 'LIMITED'">{{ $ml.get('strategies_limited') }}</span>
          </b-table-column>
          <b-table-column field="tax_percent" width="50" :label="$ml.get('strategies_fee')" sortable cell-class="leftal" v-slot="props">
            {{ props.row.tax_percent }}%
          </b-table-column>
          <b-table-column field="rooms" :label="$ml.get('strategies_users')" width="100" sortable cell-class="leftal" v-slot="props">
            {{ props.row.rooms }}
          </b-table-column>
          <b-table-column field="bets_count" :label="$ml.get('strategies_bets_count')" width="100" sortable cell-class="leftal" v-slot="props">
            {{ new Intl.NumberFormat().format(props.row.bets_count) }}
          </b-table-column>
          <b-table-column field="turnover" :label="$ml.get('strategies_turnover')" width="100" sortable cell-class="leftal" v-slot="props">
            <Money v-bind:amount="props.row.turnover" currency="EUR" v-if="props.row.turnover !== null"/>
          </b-table-column>
          <b-table-column field="roi" label="ROI" width="50" sortable cell-class="leftal" v-slot="props">
            {{ props.row.roi }}
          </b-table-column>
          <b-table-column v-slot="props">
            <ul class="horizontal">
              <li>
                <a :title="$ml.get('common_edit')" :href="`/strategies/create?id=${props.row.id}`">
                  <b-button size="is-small" type="is-info" outlined>
                    <b-icon size="is-small" icon="pencil"></b-icon>
                  </b-button>
                </a>
              </li>
              <li>
                <b-button size="is-small" type="is-danger" outlined @click="delete_(props.row.id)" :title="$ml.get('common_delete')">
                  <b-icon icon="delete" size="is-small"></b-icon>
                </b-button>
              </li>
              <li>
                <b-button tag="router-link" :title="$ml.get('common_statistic')" size="is-small" type="is-success" :to="`/statistic/strategy?id=${props.row.id}`">
                  <b-icon size="is-small" icon="chart-bar"></b-icon>
                </b-button>
              </li>
              <li>
                <b-button tag="router-link" :title="$ml.get('strategies_placed_bets')" size="is-small" type="is-primary" :to="`/bets/strategy?id=${props.row.id}`">
                  <b-icon size="is-small" icon="star"></b-icon>
                </b-button>
              </li>
            </ul>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Money from "@/components/Money";

export default {
  name: "Index",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: false,
      items: [],
    }
  },
  components: {
    Money
  },
  methods: {
    retrieve: function () {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `strategies/index`).then(resp => {
        this.items = resp.data.result.items
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
    delete_: function (id) {
      if (confirm(this.$ml.get('strategies_delete_confirm'))) {
        this.loading = true
        Vue.prototype.$http.delete(process.env.VUE_APP_API_HOST + `strategies/index?id=${id}`).then(resp => {
          this.retrieve()
          this.flashMessage.success({
            title: this.$ml.get('common_request_success'),
          })
        }).catch(err => {
          this.flashMessage.error({
            title: this.$ml.get('common_request_failed'),
            message: this.$ml.get('common_request_failed_message'),
          })
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
  mounted() {
    this.retrieve()
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_strategies')
    }
  }
}
</script>

<style scoped>
</style>